#Product {
    width: 100%;
    font-family: 'PalatinoBold';
    height: calc(100vh - 85px - 168.02px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.visBlcok.help {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#Product h1 {
    font-size: 3vw;
    text-align: center;
    padding: 25px 0;
}
#Product > div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.trioProduct {
    width: 28.01%;
    display: grid;
    grid-template-rows: 75% 1fr 15px;
    grid-template-columns: 30px 1fr 30px;
    justify-items: center;
    align-items: end;
}
.trioProduct > img {
    width: 100%;
    height: auto;
    grid-row: 1 / 4;
    grid-column: 1 / 4;
    opacity: 0.7;
    z-index: 5;
    
}
.trioProduct > img:hover {
    opacity: 1;
}
.trioProduct > div {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    text-align: center;
    z-index: 10;
    font-weight: bold;
    font-size: 2vw;
    color: white;
    text-shadow: #000 1px 0 10px;
    
}
.trioProduct.PANELS {
    width: 43.98%;
}
@media screen and (max-width: 800px) {
    #Product {
        overflow-x: hidden;
        height: calc(100vh - 282.52px - 85px);
    }
    #Product > div {
        display: grid;
        grid-template-columns: 1fr 43.98% 1fr;
    }
    #Product > div > div {
        width: 100%;
    }
    #Product > div > div img {
        width: 100%;
        height: 100%;
    }
    .TILES, .PANELS, .MATERIALS {
        grid-row: 1 / 2;
    }
    .TILES {
        grid-column: 1 / 2;
    }
    .PANELS {
        grid-column: 2 / 3;
    }
    .MATERIALS {
        grid-column: 3 / 4;
    }
}