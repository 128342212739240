* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: black; 
    scroll-margin-top: 85px;
}
html {
    scroll-behavior: smooth;
}
body { 
    overflow-x: hidden;
}
main {
    margin-top: 85px;
}
.cnt {
    display: none;
}
.visBlcok {
    display: block;
}
a {
    color: inherit;
}