footer {
    background-color: rgb(60, 60, 59);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    
    padding: 30px;
    
}
footer p {
    color: #f7f7f7;
    font-size: min(1.45vw, 15px);
}
footer > div > img {
    height: 75px;
    width: auto;
    margin: 0px 0;
}
footer > div > div > img {
    width: min(3.1vw, 38px);
    height: auto;
} 
#leftFoot > img {
    margin-left: 2vw;
    margin-right: min(1vw, 30px);
}
#leftFoot p {
    
}
#rightFoot {
    text-align: right;
    font-family: "Domascus";
}
#rightFoot strong {
    font-family: "DomascusBold";
    color: #f7f7f7;
}
#rightFoot > img {
    margin-right: 2vw;
    margin-left: min(1vw, 30px);
}
#rightFoot, #leftFoot {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


#centerFoot {
    text-align: center;
}
#centerFoot > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.centFoot {
    margin: 0px 10px;
}
@media screen and (max-width: 800px) {
    footer {
        flex-direction: column;
        padding: 15px 0;
    }
    footer p {
        font-size: 11px;
    }
    #centerFoot {
        margin: 10px 0;
        border-top: 1px solid #f7f7f7;
        border-bottom: 1px solid #f7f7f7;
        padding: 10px 0px;
        width: var(--leftFoot-width);
    }
    #centerFoot img {
        width: 20px;
    }
    #leftFoot > img {
        margin-left: 0;
        margin-right: 10px;
    }
    #rightFoot > img {
        margin-left: 10px;
        margin-right: 0;
    }
}