@font-face { 
    font-family: "PalatinoBold";
    src: url("./PalatinoBold.eot");
    src: url("./PalatinoBold.eot?#iefix")format("embedded-opentype"),
    url("./PalatinoBold.woff") format("woff"),
    url("./PalatinoBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}
@font-face{
    font-family: "Palatino ET W01 Roman";
    src: url("./Platino\ Roman/d9648fc0eeae107893076e37c96a1a3b.eot");
    src: url("./Platino\ Roman/d9648fc0eeae107893076e37c96a1a3b.eot?#iefix")format("embedded-opentype"),
        url("./Platino\ Roman/d9648fc0eeae107893076e37c96a1a3b.woff")format("woff"),
        url("./Platino\ Roman/d9648fc0eeae107893076e37c96a1a3b.woff2")format("woff2"),
        url("./Platino\ Roman/d9648fc0eeae107893076e37c96a1a3b.ttf")format("truetype"),
        url("./Platino\ Roman/d9648fc0eeae107893076e37c96a1a3b.svg#Palatino ET W01 Roman")format("svg");
    font-weight:normal;
    font-style:normal;
    font-display:swap;
}
@font-face{
    font-family: "Domascus";
    src: url("./Domascus/Damascus.ttf")format("truetype");
    font-weight:normal;
    font-style:normal;
    font-display:swap;
}
@font-face{
    font-family: "DomascusBold";
    src: url("./Domascus/DamascusBold.ttf")format("truetype");
    font-weight:bold;
    font-style:normal;
    font-display:swap;
}