#AboutUs {
    width: 100%;
}
#about1 {
    width: 100%;
    display: flex;
    background-color: rgb(60, 60, 59);
}
#about1 > div {
    padding: 5%;
}
#about1 > img {
    width: 50%;
    height: auto;
}
#fullTown {
    display: none;
}
#about1 h1 {
    font-size: 3vw;
    margin-top: 15px;
    color: rgb(60, 60, 59);
    color: #F4F4F4;
}
#about1 p {
    margin-top: 10px;
    font-size: 1.5vw;
    color: #F4F4F4;
}

#about2 {
    width: 100%;
    display: flex;
}
#about2 > div {
    padding: 5%;
}
#about2 > img {
    width: 44vw;
    height: auto;
}
#about2 h2 {
   font-size: 2.4vw;
   font-weight: bold; 
}
#about2 p {
    margin-top: 15px;
    font-size: 1.5vw;
}
#about2 > div > span {
    font-size: 2.4vw;
    font-weight: bold;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}
.unorderList {
    display: flex;
    align-items: center;
    padding: 3px 0;
}
.unorderList > div {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #007433;
}
.unorderList > span {
    font-size: 1.8vw;
    margin-left: 10px;
}
@media screen and (max-width: 800px) {
    img#fullTown {
        display: block;
        width: 100%;
        height: auto;
        grid-row: 1 / 4;
        grid-column: 1 / 4;
    }
    #halfTown {
        display: none;
    }
    #about1 {
        display: grid;
        grid-template-rows: 20% 1fr 20px;
        grid-template-columns: 10px 1fr 20%;
        z-index: 1;
    }
    #about1 > div {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        z-index: 2;
        background-color: rgba(60, 60, 59, 0.8);
    }
    #about1 h1 {
        margin-top: 10px;
    }
    #about1 p {
        font-size: 7px;
    }
    #about2 {
        align-items: center;
    }
    #about2 > img {
        width: 40vw;
    }
    #about2 h2 {
        font-size: 10px;
    }
    #about2 > div > p {
        font-size: 7px;
    }
    .unorderList span {
        font-size: 7px;
    }
    
}