#ContactPerspne {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4%;
}
#ContactPerspne > img {
    width: 30%;
    height: auto;
    border-radius: 50%;
    margin-top: 4%;
}
#ContactPerspne > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4%;
    font-family: 'PalatinoBold';
}
.cntAll {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}
.cntAll > div > a > img, .cntAll > div > a > svg {
    width: 26vw;
    height: auto;
    display: block;
}
#ContactPerspne h1 {
    font-size: 3vw;
}
#ContactPerspne h2 {
    margin-top: 1%;
    margin-bottom: 3%;
    font-size: 2.5vw;
}
#ContactPerspne h3 {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 2vw;
}