#contact {
    width: 100%;
    height: calc(100vh - 93.75px);
    background-color: #fff;
    
    display: flex;
    grid-row: 1 / 2;
}
#contact > div{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#contact iframe {
    width: 100%;
    height: 100%;
}
#formContact {
    text-align: center;
    align-self: center;
    font-family: 'PalatinoBold';
    background: linear-gradient(270deg,rgba(0, 117, 52, 1), rgba(60, 170, 54, 1) );
}
input {
    border: 0px;
    border-bottom: 1px solid black;
    width: 90%;
    text-align: center;
    font-size: 2vh;
    background-color: rgba(0, 0, 0, 0);
    border-color: #F4F4F4;
    color: #fff;
    font-family: "Palatino ET W01 Roman";
}
input::placeholder {
    color: rgba(255, 255, 255, 0.75);
}
textarea {
    width: 90%;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0);
    border-color: #F4F4F4;
    color: #fff;
}
#formContact h5{
    margin: 0%;
    font-size: 3vh;
    color: white;
}
#formContact p{
    margin: 0%;
    font-size: 2vh;
    margin: 5px;
    font-weight: 300;
    color: white;
    margin-top: 10px;
    font-family: "Palatino ET W01 Roman";
}
#police {
    font-size: 1.9vh!important;
    margin-bottom: 0px!important;
    color: white;
}
#police a{
    color: white;
    font-size: 12px!important;
}
label {
    font-size: 2.1vh;
    font-weight: 600;
    color: white;
}
form button {
    background-color: rgba(0, 117, 52, 0);
    border: 1px solid #F4F4F4;;
    font-size: 2vh;
    padding: 5px 10px;
    font-weight: 600;
    margin: 20px 0;
    cursor: pointer;
    color: white;
    font-family: "Palatino ET W01 Roman";
}
form button:hover {
    background-color: #fff;
    color: rgb(60, 60, 59);
}
span.invisFont {
    color: rgba(0, 0, 0, 0);
}
#bezs {
    visibility: hidden;
    border: none;
}

@media screen and (max-width: 800px) {
    #contact {
        width: 100%;
        height: auto;
        flex-direction: column;
    }
    #contact > div {
        width: 100%;
        height: calc(100vh - 85px);
    }
}