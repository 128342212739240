#Part1 {
    width: 100%;
    display: grid;
    grid-template-rows: 6fr 3.3fr 8vw;
    grid-template-columns: 8% 1fr 8%

}
#Part1 > img {
    width: 100%;
    height: auto;
    grid-row: 1/4;
    grid-column: 1/4;
}
#Part1 > div {
    grid-row: 2/3;
    grid-column: 2/3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    background-color: rgba(60, 60, 59, 0.8);
    
}
#Part1 > div h1, #Part1 > div h2 {
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 3vw;
    font-family: 'PalatinoBold';
}
#Part1 > div h2 {
    margin-top: 10px;
    font-size: 2.2vw;
}