.whiteTrio {
    background-color: rgb(244, 244, 244);
}
.PartTrio.whiteTrio h4 {
    color: rgb(60, 60, 59);
    font-weight: normal;
    font-size: 35px;
    font-family: 'PalatinoBold';
}
p.white {
    color: rgb(60, 60, 59);
}
button.white {
    background-color: #f4f4f4;
    color: rgb(60, 60, 59);
    border: 1px solid rgb(60, 60, 59);
}
button.white.learnMore:hover {
    background-color: rgb(60, 60, 59);
    color: #fff;
    cursor: pointer;
}
@media screen and (max-width: 800px) {
    .PartTrio {
        padding: 20px 0;
    }
    .PartTrio h3 {
        font-size: 30px;
    }
    .PartTrio > div {
        flex-direction: column;
        margin-top: 0;
    } 
    .PartTrio > div img {
        margin-top: 15px;
        width: 40vw;
        height: auto;
    }
    .PartTrio > div p {
        font-size: 20px;
    }
    .learnMore {
        margin-top: 15px;
    }
    .white.learnMore {
        border: 1px solid rgb(60, 60, 59);
    }
}