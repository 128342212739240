.PartTrio {
    width: 100%;
    background-color: rgb(60, 60, 59);
    padding: 40px 0;
    text-align: center;
} 
.PartTrio  h3, .PartTrio  p {
    color: #fff;
}
.PartTrio  h3 {
    font-weight: normal;
    font-size: 35px;
    font-family: 'PalatinoBold';
}
.PartTrio > div {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.PartTrio img { 
    height: 11vw;
    width: auto;
    
}
.PartTrio p {
    font-size: 1.5vw;
    margin-top: 20px;
    font-family: 'PalatinoBold';
}
.learnMore {
    font-size: 20px;
    margin-top: 40px;
    border: 0;
    border-radius: 0;
    padding: 10px 30px 5px 30px;
    font-family: "Palatino ET W01 Roman";
}
.gray {
    background-color: rgb(60, 60, 59);
    color: #fff;
    border: 1px solid white;
}
.gray.learnMore:hover {
    background-color: #fff;
    color: rgb(60, 60, 59);
    cursor: pointer;
}