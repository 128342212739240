#Part2 {
    width: 100%;
    display: flex;
}
#Part2 > img {
    width: 50%;
}
#Part2 > div {
    background-color: rgb(244, 244, 244);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 7%;
}
#Part2 h3, #Part2 p, #Part2 button {
    color: rgb(60, 60, 59);
}
#Part2 h3 {
    font-size: 2.5vw;
    font-weight: normal;
    font-family: 'PalatinoBold';
}
#Part2 p {
    font-family: "Palatino ET W01 Roman";
    font-size: 1.4vw;
    margin-top: 10px;
    font-weight: normal;
}
#Part2 button {
    font-family: "Palatino ET W01 Roman";
    background-color: #f4f4f4;
    font-size: 1.4vw;
    padding: 10px 30px;
    margin-top: 3vw;
    border: none;
    font-weight: normal;
    cursor: pointer;
    border: 0.5px solid rgb(60, 60, 59);
}
#Part2 button:hover {
    background-color: rgb(60, 60, 59);
    color: #fff;
}
@media screen and (max-width: 800px) {
    #Part2 {
        display: grid;
        grid-template-rows: 15px 1fr 15px;
    }
    #Part2 img {
        width: 100%;
        height: auto;
        grid-row: 1 / 4;
        grid-column: 1 / 2;
    }
    #Part2 > div {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        margin: 0 10%;
        background-color: rgba(244, 244, 244, 0.8);
    }
    #Part2 h3{
        font-size: 17px;
    }
    #Part2 p {
        font-size: 12px;
    }
    #Part2 button {
        
        padding: 5px 15px;
        font-size: 10px;
        background-color: rgba(244, 244, 244, 0);
    }
}