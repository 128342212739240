header {
    top: 0;
    width: 100%;
    display: flex;
    position: fixed;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    z-index: 100;
    background-color: #f4f4f4;
    font-family: 'PalatinoBold'
}
.menu {
    display: none;
}
header > img {
    height: 75px;
    width: auto;
    margin: 5px 0px 5px 2%;
    cursor: pointer;
}
header a, header span {
    color: rgb(60, 60, 59);
}
header > div {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-right: 2vw;
}
nav {
    display: flex;
    align-items: center;
}
nav > div {
    margin: 0 1vw;
    border-bottom-color: rgb(0, 117, 52);
    border-bottom-style: solid;
    border-bottom-width: 0pt;
    cursor: pointer;
    transition: border-bottom-width 0.1s ease-out;
}
nav > div:hover {
    border-bottom-width: 2pt;
}
.activeBorder {
    border-bottom-width: 2pt;
}
.l0Margin {
    margin-left: 0;
}
.r0Margin {
    margin-right: 0;
}
#lang {
    cursor: pointer;
}
#lang img {
    height: 29.5px;
    width: auto;
    margin-left: 2vw;

}
#lang > div {
    
    display: flex;
    align-items: center;
}
#lang span {
    margin-left: 5px;
}
@media screen and (max-width: 800px) {
    .menu {
        display: block;
        height: 30px;
        width: auto;
        margin: 0 15px;
    }
    .menu.left {
        opacity: 0;
    }
    .menu.right {
        
    }
    
    header > div {
        position: fixed;
        top: -291.5px;
        z-index: -1;
        /*top: 84.4px;*/
        background-color: #f4f4f4;
        flex-direction: column;
        width: 100%;
        transition: top 0.7s ease-in-out;
                    
    }
    .mobiMenu {
        top: 84.4px;
    }
    nav, #lang {
        opacity: 0;
        transition: opacity 0.4s ease-in-out 0.3s;
    }
    .mobiMenu nav, .mobiMenu #lang {
        opacity: 1;
    }
    nav {
        flex-direction: column;
    }
    nav > div {
        margin: 10px 0;
    }
    #lang {
        margin: 10px 0;
        margin-bottom: 20px;
    }
}