.servBase {
    width: 100%;
    display: flex;
    text-align: center;
    background-color: #F4F4F4;
    font-family: 'PalatinoBold';
}
#Service h6 {
    font-family: 'PalatinoBold';
    font-size: 2.5vw;
    text-align: center;
    padding: 1.5vw 0;
    font-weight: bold;
}
.servBase > img {
    width: 40%;
    height: auto;
}
.servBase h1 {
    font-size: 1.9vw;
    font-weight: bold;
    margin-top: 0%;
    color: rgb(60, 60, 59);
}
.servTextBase {
    width: 60%;
    padding-left: 4%;
    padding-right: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.servTextBase > p {
    text-align: justify;
    font-size: 1.2vw;
    margin: 10px 0%;
    color: rgb(60, 60, 59);
    font-family: "Palatino ET W01 Roman";
    
}
.servTextBase > div {
    width: 100%;
    display: flex;
    margin: 5px auto;
}
.servTextBase > div > div {
    padding: 0px 13px;
    text-align: justify;
   
}
.servTextBase > div > div p {
    font-size: 1.2vw;
    color: rgb(60, 60, 59);
    font-family: "Palatino ET W01 Roman";
}
.servTextBase > div > div p strong {
    font-family: 'PalatinoBold';
}
.center {
    border-right:  2px solid #007534;
    border-left:   2px solid #007534;
}
.servBase button {
    color: rgb(60, 60, 59);
    font-size: 1.6vw;
    padding: 3px 30px;
    background-color: #F4F4F4;
    border-color: rgb(60, 60, 59);
    border-width: 1px;
    font-family: "Palatino ET W01 Roman";
    margin-top: 10px;
}
.servBase button:hover {
    background-color: rgb(60, 60, 59);
    color: #F4F4F4;
    cursor: pointer;
}
.blackGra {
    background-color: rgb(60, 60, 59);
    color: #F4F4F4;
}
p.blackGra {
    color: #F4F4F4;
}
.blackGra div > .blackGra {
    color: #F4F4F4;
}
h1.blackGra {
    color: #F4F4F4!important;
}
.servBase .blackGra button {
    background-color: rgb(60, 60, 59);
    color: #F4F4F4;
    border-color: #F4F4F4;
}
.servBase .blackGra button:hover {
    background-color: #F4F4F4;
    color: rgb(60, 60, 59);
}
.center.wh {
    border-right:  2px solid #F4F4F4;
    border-left:   2px solid #F4F4F4;
}
.leftServ {
    padding-left: 0!important;
}
.rightServ {
    padding-right: 0!important;
}
@media screen and (max-width: 800px) {
    .servBase {
        align-items: center;
        flex-direction: column;
    }
    .reversMobi {
        flex-direction: column-reverse;
        background-color: rgb(60, 60, 59);
    }
    .servTextBase {
        padding-left: 0;
        padding-right: 0;
    }
    .servBase > img {
        margin-top: 25px;
        width: 90%;
        height: auto;
    }
    .servBase button{
        margin-bottom: 20px;
    }
    .servBase > div {
        width: 80%;
    }
    .servBase h1 {
        font-size: 15px;
        margin-top: 10px;
    }
    .servTextBase > p {
        font-size: 13px;
    }
    .servTextBase > div > div p {
        font-size: 11px;
    }
    .servBase button {
        font-size: 13px;
    }
}